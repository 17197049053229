<template>
  <div class="whole">
	<!-- 提币 -->
	<common-head url="/assets" :title="$t('xinzeng.tibi')" :is-return="true" @onclickRight="onclickRight">
		<img slot="right" src="@/assets/imgs/icon/icon_search.png" alt="" />
	</common-head>
	
	<div class="layoutBox bgGray">
		<div class="ulList">
			<div class="list-cell" @click="showPicker = true">
				{{ token }}
				<div class="right">
					<span class="mainFontLightColor">{{ $t("tba.choosebi") }}</span>
					<i class="icon_arrR" style="margin: 0;"></i>
				</div>
			</div>
		</div>
		<div class="contWhiteBox">
			<div class="list-cell-item">
				<div class="hd">
					<span class="mainFontColor">{{ $t('xinzeng.tibidizhi')}}</span>
				</div>
				<div class="bd">
					<input v-model="dataForm.address" type="text" class="inp" :placeholder="$t('tba.reciceMoneyAddress')" />
					<div class="scanBtn" @click="scan"></div>
				</div>
			</div>
			<div class="list-cell-item">
				<div class="hd">
					<span class="mainFontColor">{{ $t('myPledge.Number')}}</span>
					<span class="mainFontLighterColor">{{ $t('pledge.usable')}} {{token}} : {{ total }}</span>
				</div>
				<div class="bd">
					<input type="text" class="inp" v-model="dataForm.amount" @input="setArrival" :placeholder="zuixiaotibi(withdraw_min)" />
					<div class="allBtn mainColor1" @click="fullWithdraw">{{ $t('pledge.all')}}</div>
				</div>
			</div>
			<div class="list-cell-item">
				<div class="hd">
					<span class="mainFontColor">{{ $t('tba.Handlingfee')}}（{{token}}）</span>
				</div>
				<div class="bd">
					{{ dataForm.fee }}
				</div>
			</div>
		</div>
		<div class="contWhiteBox">
			<div class="list-cell-item">
				<div class="hd">
					<span class="mainFontColor">{{ $t('tba.Arrivalquantity')}}（{{token}}）</span>
				</div>
				<div class="bd">
					{{ dataForm.arrival }}
				</div>
			</div>
		</div>
		<div class="comTips" style="margin-bottom: 1.4rem;">
			<p v-if="fee_type == 'fixed'">{{ $t('tba.zuyi')}}{{withdraw_min}}usdt,{{$t('tba.shouxufei')}}{{ dataForm.fee }}{{$t('tba.shouxufei_danwei')}}</p>
			<p v-else>{{ $t('tba.zuyi')}}{{withdraw_min}}usdt,{{$t('tba.shouxufei')}}{{ fee }}{{$t('tba.shouxufei_danwei2')}}</p>
		</div>
		<!-- <div class="comBtnStyle" @click="confirmWithdraw()">{{ $t('xinzeng.tibi') }}</div> -->
		<div class="comBtnStyle" @click="confirmInfo()">{{ $t('xinzeng.tibi') }}</div>
	</div>
	
	<!-- 币种选择 -->
	<van-action-sheet
		  v-model="showPicker"
		  :round="false"
		  :actions="periodChoiceSet"
		  @select="onSelectPeriod"
		></van-action-sheet>

  </div>
</template>

<script>
import commonHead from '@/components/commonHead';
export default {
	name: "withdrawMoney",
  components: { commonHead },
  data() {
    return {
      isVerificationShow1: false,
      info: "",

      inputValue: "",
      inputCode: "",
      haveValue: false,
      haveCode: false,
      sid: "", //session id
      nation: "86", //country code
      userInput: false,
      errorInputShow: false,
      codeSending: false,
      theTime: 60,
      total: 0,
      withdraw_min: 0,
      fee: 0,
      fee_type: 0,
      fee_ratio: 0.05,
      // dataForm: {
      //   fee: 0,
      //   username: "",
      //   tradepwd: "",
      //   sid: "",
      //   code: "",
      //   phone: "",
      //   amount: "",
      //   arrival: 0,
      //   txid: "",
      //   token: "USDT",
      //   mainchain: "TRX",
      //   address: "",
      // },
	  // 20200410
	  token: "USDT",
	  // token_set: ['USDT'],
	  mainchain_set: [],
	  // 20210415[start]---------
	  showPicker: false,
	  periodChoiceSet: [{ name: 'USDT' }],
	  dataForm: {
		  token: 'USDT',
		  mainchain: 'TRX',
		  address: '',
		  arrival: 0,
		  amount: '',
		  fee: 0,
		  
		  username: '', //本地获取-getUserInfo获取
		  txid: '', //初始化-赋值--申请提现接口获取
		  
		  phone: '', //本地获取-getUserInfo获取
		  code: '', //发送的验证码-输入
		  sid: '', //验证码发送获取sid-赋值
		  
		  tradepwd: '', //交易密码
	  },
    };
  },
  mounted() {
	  this.prepareWithdraw()
	// -----20200411[start]-----
	this.getSupportedTokens();
	this.setArrival();
	// -----20200411[end]-----
	console.log('dataForm', this.dataForm)
  },
  created() {
    if (window.plus) {
      this.plusReady();
    } else {
      document.addEventListener("plusready", this.plusReady, false); //
    }
  },
  methods: {
	  zuixiaotibi(v) {
	  	return this.$t('tba.Minimumwithdrawalamount') + v
	  },
	  onclickRight() {
	  		  this.$router.push({
	  			  path: '/walletRecord',
	  			  query: {
	  				  cashType: 'withdraw'
	  			  }
	  		  })
	  },
	  
	  onSelectPeriod(item) {
		  this.showPeriodChoice = false;
		  this.token = item.name;
		  this.dataForm.token = item.name;
		  this.showPicker = false;
	  },
	  
	  // 获取申请提现txid
	  prepareWithdraw() {
	  	this.$post2('Pay/Api/Index/prepareWithdrawConfig', {})
	  		.then(res => {
	  			console.log(res);
	  			this.total = res.amount;
	  			this.withdraw_min = res.withdraw_min;
	  			this.dataForm.amount = 0;
	  			this.dataForm.arrival = 0;
	  			this.fee_type = res.fee_type;
	  			this.fee_ratio = res.fee_ratio;
	  			if (res.fee_type == 'fixed') {
					this.dataForm.fee = res.fee;
	  				this.fee = res.fee + 'USDT';
	  			} else {
					this.dataForm.fee = res.fee_ratio * 100 + '%';
	  				this.fee = res.fee_ratio * 100 + '%';
	  			}
				
				// 20210415------
				this.dataForm.txid = res.txid;
	  		})
	  		.catch(err => {
	  			console.log(err);
	  		});
	  },
	  
	  // ----------20200410[start]----------
	  // 提币-跳转页面
	  confirmInfo() {
		  if (this.dataForm.address.length != 34 || this.dataForm.address.substr(0, 1) != 'T') {
		    // this.$toast.fail("提币地址不对");
			this.$toast(this.$t('tba.tibiAddressError'))
		    return;
		  }
		  if (this.dataForm.amount == '') {
			this.$toast(this.$t('tba.errorWithdrawAmount'))
			return
		  }
		  if (Number(this.dataForm.amount) < this.withdraw_min) {
			  console.log(this.dataForm.amount,this.withdraw_min);
			  this.$toast(this.$t('tba.Minimumwithdrawalamount') + this.withdraw_min)
			  return
		  }
		  
		  this.$router.push({
		  			  path: '/withdrawMoneyInfo',
		  			  query: {
		  				  // token: this.token,
		  				  // mainchain_set: this.dataForm.mainchain,
		  				  // address: this.dataForm.address,
		  				  // arrival: this.dataForm.arrival,
						  dataForm: this.dataForm
		  			  }
		  });
	  },
	  
	  // 获取提币地址
	  getDepositAddress() {
	    this.$post2("Pay/Api/Index/getDepositAddress", {
	      token: this.token,
	      mainchain: this.dataForm.mainchain,
	    })
	      .then((res) => {
	        // this.dataForm.address = res.address;
	      })
	      .catch((err) => {
	        this.$dialog.alert({
	          message: this.$t("xinzeng.buzhichi"),
	        });
	      });
	  },
	  
	  
	  
	  //获取-初始化提币信息
	  getSupportedTokens() {
	    this.$post2("Pay/Api/Index/getSupportedTokens")
	      .then((res) => {
			  // console.log('getSupportedTokens', res)
	        this.dataForm.mainchain = res.mainchain_set[0];
	        // console.log(this.dataForm.mainchain);
	        // this.getDepositAddress();
	      })
	      .catch((err) => {
	        this.$toast("请检查网络");
	      });
	  },
	  // ----------20200410[end]----------
    scan() {
      this.dataForm.address = this.$scan();

      // this.$toast.success(result);
    },
    plusReady() {
      var ws = plus.webview.currentWebview(); //pw回车可输出plus.webview
    },

    getImage() {
      let cmr = plus.camera.getCamera(); // 获取摄像头对象
      let res = cmr.supportedImageResolutions[0]; // 字符串数组，摄像头支持的拍照分辨率
      let fmt = cmr.supportedImageFormats[0]; // 字符串数组，摄像头支持的拍照文件格式
      console.log("Resolution :" + res + ", Format: " + fmt);
      cmr.captureImage(
        (path) => {
          alert("调用成功: " + path);
        },
        (error) => {
          // 拍照操作失败的回调函数
          alert("调用失败: " + error.message);
        },
        { resolution: res, format: fmt } // 摄像头拍照参数
      );
    },

	
	// 初始化-手续费-到账数量
    setArrival() {
   //    let v = this.dataForm.amount - this.dataForm.amount * this.fee_ratio;
   //    if (v > 0) {
   //      this.dataForm.arrival = v.toFixed(2);
   //      let fee2 = this.dataForm.amount - this.dataForm.arrival;
   //      this.dataForm.fee = fee2.toFixed(2);
	  // }
	  
	  let amount = this.dataForm.amount
	  let total = this.total
	  if(amount < this.withdraw_min) {
		  this.$toast('低于最小提币数量')
	  }
	  if (amount > this.total) {
		  this.$toast('超出可用余额')
		  this.dataForm.amount = total
		  amount = total
	  }
	  
	  if (this.fee_type == 'fixed') {
	  	// this.fee = res.fee + 'USDT';
		// 固定费用
		let v = amount - this.dataForm.fee;
		if (v > 0) {
			this.dataForm.arrival = Math.floor(v *100)/100;
		} else {
			this.dataForm.arrival = 0;
		}
	  } else {
	  	// this.fee = res.fee_ratio * 100 + '%';
		// 按照提币数量*比例计算
		let v = amount - amount * this.fee_ratio;
		this.dataForm.arrival = Math.floor(v *100)/100;
		let fee2 = amount - this.dataForm.arrival;
		this.dataForm.fee = Math.floor(fee2 *100)/100;
	  }
    },
	
	// 全部提取
    fullWithdraw() {
      this.dataForm.amount = this.total;
      this.setArrival();
    },
	
  },
};
</script>

<style scoped lang="less">
.inputSpan {
  font-size: 0.3rem;
  color: #ff4b04;
  padding-top: 0.2rem;
  float: right;
}

.fdStyle {
  background: #f1f1f1;
  height: 100%;
  /* padding: 0.3rem; */
}

.van-ellipsis {
  color: #ffffff !important;
  /* background: green; */
}

.van-nav-bar {
  background: #ecbb34;
}

.van-icon {
  color: #ffffff;
}

.endList {
  margin-top: 2.3rem;
}

.btnDivStyle {
  margin-top: 0.7rem;
  margin-bottom: 0.1rem;
  text-align: center;
}

.bodyStyle {
  background: #f1f1f1;
  height: 100%;
}

.vanCellStyle1 {
  /* margin-left: 0.4rem;
        margin-right: 0.4rem; */
  border-radius: 5px;
  margin-top: 0.2rem;
  /* background: #f1f1f1; */
}

.vanCellStyle2 {
  /* margin-left: 0.4rem;
        margin-right: 0.4rem; */
  border-radius: 5px;
  margin-top: 0.3rem;
  /* background: #f1f1f1; */
}

.van-icon-arrow-left {
  color: #ffffff;
}

.van-hairline--bottom {
  background: #ecbb34;
  color: #ffffff;
}

.van-nav-bar__title {
  background: #ecbb34;
  color: #ffffff;
}

/* .van-ellipsis {
      background: 3d8051;
    } */


.inputPapaStyle {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #f2f2f2;
}

.inputStyle {
  border: 0px;
  /* border-top: 0px;
        border-left: 0px;
        border-right: 0px; */
  width: 100%;
  /* border-bottom: 1px solid #f2f2f2; */
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
  color: #8b7575;
  padding: 0.1rem;
}

.whole {
  /* background: #ffffff; */
}

.title {
  background: #f6f8f9;
  height: 2rem;
  padding: 0.3rem;
}

.titleGuanLiStyle {
  font-size: 0.4rem;
  padding-top: 1.5rem;
}

.titlePStyle {
  margin-bottom: 0.2rem;
  margin-top: 0.3rem;
  padding-top: 0.1rem;
  transform: rotate(180deg);
}

// ----------20200411[start]-----------
.layoutBox {
	// min-height: calc(100vh - 1.76rem);
	min-height: calc(100vh - .88rem);
	padding: 0 .3rem .5rem;
}

.ulList {
  padding: .1rem 0;
  .list-cell {
	  height: .88rem;
	  line-height: .88rem;
	  padding: 0 .25rem;
	  background: #FFFFFF;
	  border-radius: .12rem;
	  margin-bottom: .1rem;
	  position: relative;
	  .right {
		  position: absolute;
		  z-index: 1;
		  right: .25rem;
		  bottom: 0;
	  }
  }
}

.contWhiteBox {
	background: #FFFFFF;
	border-radius: .12rem;
	margin-bottom: .25rem;
}
.list-cell-item {
	padding: .2rem .25rem;
	border-bottom: 1px solid #EDEDED;
	line-height: .45rem;
	&:last-child {
		border-bottom: 0;
	}
	.hd {
		font-size: .24rem;
		display: flex;
		justify-content: space-between;
		margin-bottom: .25rem;
	}
	.bd {
		display: flex;
		justify-content: space-between;
		position: relative;
	}
	.inp {
		font-size: .28rem;
		height: .45rem;
		display: block;
		width: 100%;
		flex: 1;
		border: 0;
		outline: 0;
	}
	input::-webkit-input-placeholder,
	input:-moz-placeholder,
	input::-moz-placeholder,
	input:-ms-input-placeholder {
		color: #999999;
	}
	.scanBtn {
		width: .45rem;
		height: .45rem;
		background: url(../../assets/imgs/icon/icon_scancode.png) no-repeat center center;
		background-size: .4rem .4rem;
	}
}
.comTips {
	color: #999999;
	font-size: .24rem;
	line-height: .45rem;
}
.comBtnStyle {
	color: #FFFFFF;
	font-size: .32rem;
	text-align: center;
	height: .88rem;
	line-height: .88rem;
	background: #90C4FF;
	border-radius: .12rem;
}
// ----------20200411[end]-----------
</style>
